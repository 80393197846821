/* .custom-step .rs-steps-item-icon {
    background-color: red !important;
    border-color: red !important;
    color: white !important;
  } */
  
  /* .custom-step .rs-steps-item-icon::after {
    background-color: red !important;
  }
  
  .custom-step .rs-steps-item-title {
    color: red !important;
  } */


  .custom-step .rs-steps-item-icon-wrapper > .rs-steps-item-icon .rs-icon{
    color: #990000
  }
  
  .rs-theme-light {
    --rs-primary-500: #990000;
  }