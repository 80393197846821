@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'flowbite';


@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
  .no-scrollbar {
      -ms-overflow-style: none; 
      scrollbar-width: none; 
}
}

*::-webkit-scrollbar {
  width: 14px;
  height: 10px;

}

*::-webkit-scrollbar-track {
  /* background: var(--secondary); */
  color:red;
  border-radius: 5px;

}


*::-webkit-scrollbar-thumb {
  background-color: rgb(153,0,0);
  border-radius: 14px;
  height: 10px;
  /* border: 3px solid var(--primary); */
}

.donut-chart-container {
  position: relative;
  .center-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: normal;
  }
}

html {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

tbody {
  border-bottom: 1px solid lightgray;
}

tfoot {
  color: gray;
}

tfoot th {
  font-weight: normal;
}

.specifyColorRadioButton {
  accent-color: #990000;
}



.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}





