/* Custom calendar background and general style */
.custom-calendar {
  background-color: #e5e7eb; /* Tailwind's bg-gray-400 */
  border-radius: 12px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  padding: 20px;
}

/* Navigation buttons (next/previous month) */
.custom-calendar .react-calendar__navigation button {
  background-color: rgb(153 0 0 / var(--tw-bg-opacity)); /* Tailwind's blue-500 */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 2px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 20px;
}
.month_ .react-calendar__tile{
  padding: 5px;
  border-radius: 8px;
  font-size: 16px;
  max-width: 48px;
  margin: 6px;
  color: #111827; 
  transition: background-color 0.2s ease;
  background-color: #d1d5db;
  color: #9ca3af; 
}
.active-month {
  background-color: #4caf50;
  color: white;
  border-radius: 50%;
}
.custom-calendar .react-calendar__navigation button:hover {
  background-color: #2563eb; /* Darker blue for hover */
}

/* Weekdays (Mon, Tue, etc.) */
.custom-calendar .react-calendar__month-view__weekdays {
  font-weight: bold;
  color: #6b7280; /* Tailwind's gray-500 */
  text-align: center;
  margin-bottom: 10px;
  /* margin-left: 5px; */
}


/* Days in the month */
.custom-calendar .react-calendar__tile {
  padding: 5px;
  border-radius: 8px;
  font-size: 16px;
  max-width: 48px;
  margin: 6px;
  color: #111827; 
  transition: background-color 0.2s ease;
  background-color: #d1d5db;
  color: #9ca3af; 
}
.custom-calendar_month .react-calendar__tile {
  padding: 5px;
  border-radius: 8px;
  font-size: 16px;
  max-width: 108px;
  margin: 6px;
  color: #111827; 
  transition: background-color 0.2s ease;
  background-color: #d1d5db;
  color: #9ca3af; 
}
.custom-calendar .react-calendar__tile_available {
  color: #111827; 
  background-color: #54CF54;
}

.custom-calendar .react-calendar__tile_save {
  color: white;

}

.custom-calendar .react-calendar__tile:hover {
  background-color: #d1d5db; 
 
}

.custom-calendar .react-calendar__tile--active {
  background-color:rgb(153 0 0 / var(--tw-bg-opacity)); 
  color: white;
}


/* Disable day styles (for past/future dates outside current month) */
.custom-calendar .react-calendar__tile--disabled {
  background-color: #d1d5db;
  color: #9ca3af; 

}
.element {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
.element::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}